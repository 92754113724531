<template>
  <div class="row">
    <div class="col-12 px-0">
      <div class="course-provider" :style="{ height: showAllCourses ? '600px' : 'auto' }" @click="() =>
        $router.push({
          path: `/university-detail/${item.university_id}`,
        })
        ">
        <div class="row mb-1 align-items-center">
          <div class="col-2">
            <figure>
              <img :src="item.university_logo" alt="" class="w-100" />
            </figure>
          </div>
          <div class="mb-1 ">
            <span class="course-provider__name">{{
              item.university_name
            }}</span>
            <div class="d-flex align-items-center">
              <span class="course-provider__point">
                <img src="@/assets/images/svg/location.svg" alt="" />
                {{ item.country_name }}, {{ item.state_name }}
              </span>
              <!-- <span class="course-provider__point">
              <img src="@/assets/images/svg/location.svg" alt="" />
              {{ item.city_name }}
            </span> -->
            </div>
            <!-- <div class="d-flex align-items-center">
              <span v-if="
                item.rating_stars && !isNaN(parseInt(item.rating_stars))
              " class="course-provider__point mr-3">
                <img v-for="(item, index) in parseInt(item.rating_stars)" :key="'star_' + index"
                  src="@/assets/images/svg/star-yellow.svg" alt="" />
              </span>
            </div> -->
          </div>

        </div>
        <div class="col-12 px-0">
          <div class="course">
            <div class="d-flex align-items-center justify-content-between mb-1">
              <div class="col-8">
                <h6 class="course__title mb-0">{{ item.course_name }}</h6>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between flex-wrap ">
              <div class="course-point">
                <img src="@/assets/images/svg/time.svg" alt="Time" />
                <div>

                  <p>Tenure</p>
                  <p>{{
                    item.course_year
                  }} Years</p>
                </div>
              </div>

              <div class="course-point">
                <img src="@/assets/images/svg/money.svg" alt="Money" />
                <div>

                  <p>Annual Fees</p>
                  <p>{{
                    item.course_fee
                  }} {{
                      item.currency
                    }}</p>
                </div>
              </div>

              <div class="course-point">
                <img src="@/assets/images/svg/cap.svg" alt="Money" />
                <div>

                  <p>Qualifcation</p>
                  <p>{{
                    item.course_level_name
                  }}</p>
                </div>
              </div>

              <div class="course-point">
                <img src="@/assets/images/svg/time.svg" alt="Time" />
                <div>

                  <p>Intakes</p>
                  <p>{{
                    item.intake
                  }}</p>
                </div>
              </div>

              <div class="course-point">
                <img src="@/assets/images/svg/star-green.svg" alt="bookmark" />
                <div>

                  <p>Mode</p>
                  <p>{{
                    item.university_mode
                  }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div v-if="item.courses && item.courses.length > 0">
          <div v-for="i in showAllCourses
            ? item.courses
            : item.courses.slice(0, 2)" :key="'course_id_' + i.course_id" @click.stop.prevent="gotToCourseDetails(i)">
            <Course :course="i" @apply-course-for-student="(e) => {
              $emit('apply-course-for-student', e);
            }
              " @recommend-course="(e) => {
                $emit('recommend-course', e);
              }
                " @apply-course="(e) => {
                  $emit('apply-course', e);
                }
                  " @reload-list="(e) => {
                    $emit('reload-list');
                  }
                    " />
          </div>
        </div>
        <div v-if="item.course_ids && item.course_ids.split(',').length > 2" class="text-center course-provider__bottom"
          :style="{ bottom: showAllCourses ? '1.3rem' : 'auto' }">
          <b-button @click.stop.prevent="() =>
            $router.push({
              path: `/university-detail/${item.university_id}`,
            })
            " v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" size="sm">
            VIEW {{ item.all_courses_count.split(",").length }} MORE COURSES
          </b-button>
          <b-button @click.stop.prevent="toggleCourses" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary"
            size="sm">
            {{
              showAllCourses
                ? "SHOW LESS COURSES"
                : `VIEW ${item.course_ids.split(",").length} MORE COURSES`
            }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Course from "./Course";
import axios from "axios";

import {
  BButton,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BImg,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
import { FILESURL } from "@/config";
import Navigation from "../../University Detail/Navigation.vue";

export default {
  name: "CourseProvider",
  components: {
    Course,
    Navigation,
    BButton,
    AppCollapse,
    AppCollapseItem,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BImg,
  },
  directives: {
    Ripple,
  },

  props: {
    university: {
      type: Object,
      default: {}
    },
    item: {
      type: Object,
      default: {},
    },
    universityDetail: {
      type: Boolean,
      default: true,
    },
    collapseCourse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAllCourses: false,
      FILESURL,
      // university_logo: "@/assets/images/svg/stanford.svg"
    };
  },
  computed: {
    // university_logo() {
    //   if (this.item.university_logo) {
    //     return this.item.university_logo;
    //   } else {
    //     return "../../../../../assets/images/svg/stanford.svg";
    //   }
    // },
    university_logo() {
      return this.item.university_logo
        ? this.item.university_logo
        : require("@/assets/images/svg/stanford.svg");
    },
  },
  async created() {
    this.fetchUniversities();
  },
  methods: {
    async fetchUniversities() {
      this.loading = true;
      try {
        const payload = {}; // Define your payload here
        const response = await axiosInstance().get("/home/search/list", { params: payload });
        if (response.data.status) {
          this.universities = response.data.data.data;
        } else {
          this.universities = [];
        }
      } catch (error) {
        console.error("Error fetching universities:", error);
        this.universities = [];
      } finally {
        this.loading = false;
      }
    },
    toggleCourses() {
      this.showAllCourses = !this.showAllCourses;
    },
    gotToCourseDetails(course) {
      this.$router.push({
        path: `/university-detail/${course.university_id}?course_id=${course.course_id}`,
      });
    },
    toggleCourses() {
      this.showAllCourses = !this.showAllCourses;
    },
  },
};
</script>
<style>
.course-provider {
  overflow-y: scroll;
}

.course__title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.course__title:hover {
  white-space: normal;
  text-overflow: clip;
}

.course-point {
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  margin-right: 20px;
}

.course-detail>div {
  margin-bottom: 10px;
}

.course-detail__sm-title {
  font-weight: bold;
  text-decoration: underline;
  text-transform: uppercase;
}

.flex-commission {
  display: flex;
  align-items: center;
  gap: 10px;
}

.info-tag {
  color: var(--primary-1);
  font-weight: bold;
  border: 1px solid var(--primary-1);
  border-radius: 50%;
  padding: 0px 06px;
}

.tooltip-inner {
  color: #fff;
  background: #ff9900 !important;
}

.duration-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  /* Center items horizontally */
}

/* Style the individual duration items with fixed spacing */
.duration-item {
  /* margin: 10px 0; */
  text-align: left;
}
</style>
